"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RollUpdater = void 0;
function updateRoll(particle, delta) {
    const roll = particle.options.roll;
    const speed = particle.rollSpeed * delta.factor;
    const max = 2 * Math.PI;
    if (!roll.enable) {
        return;
    }
    particle.rollAngle += speed;
    if (particle.rollAngle > max) {
        particle.rollAngle -= max;
    }
}
class RollUpdater {
    isEnabled(particle) {
        const rotate = particle.options.rotate;
        const rotateAnimation = rotate.animation;
        return !particle.destroyed && !particle.spawning && !rotate.path && rotateAnimation.enable;
    }
    update(particle, delta) {
        if (!this.isEnabled(particle)) {
            return;
        }
        updateRoll(particle, delta);
    }
}
exports.RollUpdater = RollUpdater;
