"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AngleUpdater = void 0;
const Enums_1 = require("../../Enums");
function updateAngle(particle, delta) {
    var _a;
    const rotate = particle.options.rotate;
    const rotateAnimation = rotate.animation;
    const speed = ((_a = particle.rotate.velocity) !== null && _a !== void 0 ? _a : 0) * delta.factor;
    const max = 2 * Math.PI;
    if (!rotateAnimation.enable) {
        return;
    }
    switch (particle.rotate.status) {
        case Enums_1.AnimationStatus.increasing:
            particle.rotate.value += speed;
            if (particle.rotate.value > max) {
                particle.rotate.value -= max;
            }
            break;
        case Enums_1.AnimationStatus.decreasing:
        default:
            particle.rotate.value -= speed;
            if (particle.rotate.value < 0) {
                particle.rotate.value += max;
            }
            break;
    }
}
class AngleUpdater {
    isEnabled(particle) {
        const rotate = particle.options.rotate;
        const rotateAnimation = rotate.animation;
        return !particle.destroyed && !particle.spawning && !rotate.path && rotateAnimation.enable;
    }
    update(particle, delta) {
        if (!this.isEnabled(particle)) {
            return;
        }
        updateAngle(particle, delta);
    }
}
exports.AngleUpdater = AngleUpdater;
