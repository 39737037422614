"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadSlim = void 0;
const Circle_1 = require("./Shapes/Circle");
const Image_1 = require("./Shapes/Image");
const Line_1 = require("./Shapes/Line");
const Polygon_1 = require("./Shapes/Polygon");
const Square_1 = require("./Shapes/Square");
const Star_1 = require("./Shapes/Star");
const Text_1 = require("./Shapes/Text");
const Angle_1 = require("./Updaters/Angle");
const Color_1 = require("./Updaters/Color");
const Life_1 = require("./Updaters/Life");
const Opacity_1 = require("./Updaters/Opacity");
const Size_1 = require("./Updaters/Size");
const StrokeColor_1 = require("./Updaters/StrokeColor");
const OutModes_1 = require("./Updaters/OutModes");
const Roll_1 = require("./Updaters/Roll");
const Tilt_1 = require("./Updaters/Tilt");
const Wobble_1 = require("./Updaters/Wobble");
const Attract_1 = require("./Interactions/External/Attract");
const Bounce_1 = require("./Interactions/External/Bounce");
const Bubble_1 = require("./Interactions/External/Bubble");
const Connect_1 = require("./Interactions/External/Connect");
const Grab_1 = require("./Interactions/External/Grab");
const Repulse_1 = require("./Interactions/External/Repulse");
const Trail_1 = require("./Interactions/External/Trail");
const Attract_2 = require("./Interactions/Particles/Attract");
const Collisions_1 = require("./Interactions/Particles/Collisions");
const Links_1 = require("./Interactions/Particles/Links");
function loadSlim(tsParticles) {
    Attract_1.loadExternalAttractInteraction(tsParticles);
    Bounce_1.loadExternalBounceInteraction(tsParticles);
    Bubble_1.loadExternalBubbleInteraction(tsParticles);
    Connect_1.loadExternalConnectInteraction(tsParticles);
    Grab_1.loadExternalGrabInteraction(tsParticles);
    Repulse_1.loadExternalRepulseInteraction(tsParticles);
    Trail_1.loadExternalTrailInteraction(tsParticles);
    Attract_2.loadParticlesAttractInteraction(tsParticles);
    Collisions_1.loadParticlesCollisionsInteraction(tsParticles);
    Links_1.loadParticlesLinksInteraction(tsParticles);
    Circle_1.loadCircleShape(tsParticles);
    Image_1.loadImageShape(tsParticles);
    Line_1.loadLineShape(tsParticles);
    Polygon_1.loadPolygonShape(tsParticles);
    Square_1.loadSquareShape(tsParticles);
    Star_1.loadStarShape(tsParticles);
    Text_1.loadTextShape(tsParticles);
    Life_1.loadLifeUpdater(tsParticles);
    Opacity_1.loadOpacityUpdater(tsParticles);
    Size_1.loadSizeUpdater(tsParticles);
    Angle_1.loadAngleUpdater(tsParticles);
    Tilt_1.loadTiltUpdater(tsParticles);
    Roll_1.loadRollUpdater(tsParticles);
    Wobble_1.loadWobbleUpdater(tsParticles);
    Color_1.loadColorUpdater(tsParticles);
    StrokeColor_1.loadStrokeColorUpdater(tsParticles);
    OutModes_1.loadOutModesUpdater(tsParticles);
}
exports.loadSlim = loadSlim;
