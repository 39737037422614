"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpacityUpdater = void 0;
const Enums_1 = require("../../Enums");
const Utils_1 = require("../../Utils");
function checkDestroy(particle, destroy, value, minValue, maxValue) {
    switch (destroy) {
        case Enums_1.DestroyType.max:
            if (value >= maxValue) {
                particle.destroy();
            }
            break;
        case Enums_1.DestroyType.min:
            if (value <= minValue) {
                particle.destroy();
            }
            break;
    }
}
function updateOpacity(particle, delta) {
    var _a, _b;
    const opacityOpt = particle.options.opacity;
    const opacityAnim = opacityOpt.animation;
    const minValue = particle.opacity.min;
    const maxValue = particle.opacity.max;
    if (!(!particle.destroyed &&
        opacityAnim.enable &&
        (opacityAnim.count <= 0 || particle.loops.opacity < opacityAnim.count))) {
        return;
    }
    switch (particle.opacity.status) {
        case Enums_1.AnimationStatus.increasing:
            if (particle.opacity.value >= maxValue) {
                particle.opacity.status = Enums_1.AnimationStatus.decreasing;
                particle.loops.opacity++;
            }
            else {
                particle.opacity.value += ((_a = particle.opacity.velocity) !== null && _a !== void 0 ? _a : 0) * delta.factor;
            }
            break;
        case Enums_1.AnimationStatus.decreasing:
            if (particle.opacity.value <= minValue) {
                particle.opacity.status = Enums_1.AnimationStatus.increasing;
                particle.loops.opacity++;
            }
            else {
                particle.opacity.value -= ((_b = particle.opacity.velocity) !== null && _b !== void 0 ? _b : 0) * delta.factor;
            }
            break;
    }
    checkDestroy(particle, opacityAnim.destroy, particle.opacity.value, minValue, maxValue);
    if (!particle.destroyed) {
        particle.opacity.value = Utils_1.clamp(particle.opacity.value, minValue, maxValue);
    }
}
class OpacityUpdater {
    isEnabled(particle) {
        const opacityAnim = particle.options.opacity.anim;
        return (!particle.destroyed &&
            !particle.spawning &&
            opacityAnim.enable &&
            (opacityAnim.count <= 0 || particle.loops.opacity < opacityAnim.count));
    }
    update(particle, delta) {
        if (!this.isEnabled(particle)) {
            return;
        }
        updateOpacity(particle, delta);
    }
}
exports.OpacityUpdater = OpacityUpdater;
