{
    "background": {
        "position": "50% 50%",
        "repeat": "no-repeat",
        "size": "cover"
    },
    "fullScreen": {
        "enable": false,
        "zIndex": -1
    },
    "interactivity": {
        "events": {
            "onClick": {
                "enable": false,
                "mode": "bubble"
            },
            "onHover": {
                "enable": true,
                "mode": "grab"
            }
        },
        "modes": {
            "bubble": {
                "distance": 400,
                "duration": 2,
                "opacity": 0.8,
                "size": 40
            },
            "grab": {
                "distance": 200,
                "links": {
                    "blink": true
                }
            }
        }
    },
    "particles": {
        "color": {
            "value": "#c1c1c1"
        },
        "links": {
            "color": {
                "value": "#c1c1c1"
            },
            "distance": 100,
            "enable": true,
            "warp": true,
            "opacity": 0.4
        },
        "move": {
            "attract": {
                "rotate": {
                    "x": 600,
                    "y": 1200
                }
            },
            "enable": true,
            "path": {},
            "outModes": {
                "bottom": "out",
                "left": "out",
                "right": "out",
                "top": "out"
            },
            "speed": 1,
            "spin": {},
            "warp": true
        },
        "number": {
            "density": {
                "enable": true
            },
            "value": 100
        },
        "opacity": {
            "value": 0.5,
            "animation": {
                "speed": 3,
                "minimumValue": 0.1
            }
        },
        "size": {
            "random": {
                "enable": true
            },
            "value": {
                "min": 1,
                "max": 3
            },
            "animation": {
                "speed": 1,
                "minimumValue": 0.1
            }
        }
    }
}