"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFull = void 0;
const plugin_1 = require("./Plugins/Absorbers/plugin");
const plugin_2 = require("./Plugins/Emitters/plugin");
const plugin_3 = require("./Plugins/PolygonMask/plugin");
const slim_1 = require("./slim");
function loadFull(tsParticles) {
    slim_1.loadSlim(tsParticles);
    plugin_1.loadAbsorbersPlugin(tsParticles);
    plugin_2.loadEmittersPlugin(tsParticles);
    plugin_3.loadPolygonMaskPlugin(tsParticles);
}
exports.loadFull = loadFull;
